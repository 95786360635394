import { combineReducers } from 'redux';

// API state
import login from './ApiState/login';
import register from './ApiState/register';
import backendDateTime from './ApiState/backendDateTime';
import hotReloadObserver from './ApiState/hotReloadObserver';
import minVersion from './ApiState/minVersion';

// Users
import userCompanies from './ApiState/User/userCompanies';
import userPersonalInfo from './ApiState/User/userPersonalInfo';
import userProfilePicture from './ApiState/User/userProfilePicture';

// Vehicle
import vehicleTypes from './ApiState/Vehicle/getVehicleTypes';
import vehicleDescriptions from './ApiState/Vehicle/getVehicleDescriptions';
import vehicleEquipments from './ApiState/Vehicle/getVehicleEquipments';
import companyVehicles from './ApiState/Vehicle/getCompanyVehicles';

// Cargo
import packagingMethods from './ApiState/Cargo/packagingMethods';

// Warehouse
import getWarehouseTypes from './ApiState/Warehouse/getWarehouseTypes';
import getWarehouseEquipment from './ApiState/Warehouse/getWarehouseEquipment';
import getWarehouseTemperatureControl from './ApiState/Warehouse/getWarehouseTemperatureControl';
import getWarehouseStorageType from './ApiState/Warehouse/getWarehouseStorageType';
import getWarehouseStorageUnit from './ApiState/Warehouse/getWarehouseStorageUnit';
import warehousePricingInterval from './ApiState/Warehouse/getWarehousePricingInterval';

// Logical state
import language from './LogicalState/changeLanguage';
import filterNameState from './LogicalState/filterNameState';
import session from './LogicalState/sessionData';
import authNavBar from './LogicalState/authNavBarState';
import sideBarHomeState from './LogicalState/sideBarHomeState';
import registerState from './LogicalState/registerState';
import middlewareError from './LogicalState/middlewareError';
import handleErrors from './LogicalState/handleErrors';
import isUserVerified from './LogicalState/isUserVerified';
import internetConnection from './LogicalState/internetConnection';
import wholePageLoader from './LogicalState/wholePageLoaderState';

import companySuspendedModalState from './LogicalState/companySuspendedModalState';
import tooManyRequestsModalState from './LogicalState/tooManyRequestsModalState';

// Socket
import messageReceived from './Socket/messageReceived';
import notificationDots from './Socket/notificationDots';
import notificationMessages from './Socket/notificationMessages';
import chatMessages from './Socket/chatMessages';

import { RESET_APP } from '../../constants/actions';
import { RELOAD_REDUX_STATE } from '../../constants/actions';

// Filter
import vehicleOffersFilter from './LogicalState/Filter/VehicleOffersFilter';
import cargoOffersFilter from './LogicalState/Filter/CargoOffersFilter';
import warehouseOffersFilter from './LogicalState/Filter/WarehouseOffersFilter';

import userSettings from './ApiState/User/userSettings';

import NewUsersTunnel from './LogicalState/NewUsersTunnel';

import { getReduxStateFromStorage } from '../../assets/helpers/utils';

import drawers from './LogicalState/Drawers';

import planner from './LogicalState/planner';

import contacts from './LogicalState/contacts';

const appReducer = combineReducers({
  /* APPLICATION TOP-LEVEL REDUCERS */

  // API state
  login,
  register,
  backendDateTime,
  hotReloadObserver,
  minVersion,

  // USERS
  userCompanies,
  userPersonalInfo,
  userSettings,
  userProfilePicture,

  // VEHICLE
  vehicleTypes,
  vehicleDescriptions,
  vehicleEquipments,
  companyVehicles,

  // Cargo
  packagingMethods,

  // Warehouse
  getWarehouseTypes,
  getWarehouseEquipment,
  getWarehouseTemperatureControl,
  getWarehouseStorageType,
  getWarehouseStorageUnit,
  warehousePricingInterval,

  // Logical state
  language,
  session,
  authNavBar,
  sideBarHomeState,
  registerState,
  middlewareError,
  handleErrors,
  isUserVerified,
  internetConnection,
  wholePageLoader,

  companySuspendedModalState,
  tooManyRequestsModalState,

  // Socket
  messageReceived,
  notificationDots,
  notificationMessages,
  chatMessages,

  // Filter
  filterNameState,
  vehicleOffersFilter,
  cargoOffersFilter,
  warehouseOffersFilter,

  // New users tunnel
  NewUsersTunnel,

  drawers,
  planner,
  contacts
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = {
      language: state.language
    };
  }

  if (action.type === RELOAD_REDUX_STATE) {
    state = {
      ...state,
      ...getReduxStateFromStorage()
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
