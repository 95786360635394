import { SET_RELOAD_CONTACTS_DASHBOARD, SET_RELOAD_CONTACT } from '../../../constants/actions';

const initialState = {
  reloadContactsDashboard: false,
  setReloadContact: false
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RELOAD_CONTACTS_DASHBOARD:
      return { ...state, reloadContactsDashboard: action.payload };
    case SET_RELOAD_CONTACT:
      return { ...state, setReloadContact: action.payload };
    default:
      return state;
  }
};

export default contactReducer;
