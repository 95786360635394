import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';
import Modal from 'react-modal';

import CircleLoader from '../Loader/CircleLoader';
import FourDotsLoader from '../Loader/FourDotsLoader';
import ModalComponent from '../ModalComponent/ModalComponent';
import AppComponentContext from '../../../contexts/AppComponentContext';
import Tooltip from '../Tooltip/Tooltip';
import { generateRandomId } from '../../../assets/helpers/common';
import useCountdown from '../../../hooks/useCountdown';
import ClockSvg from '../../../assets/images/svg/ClockSvg';

Modal.setAppElement('body');

const Button = React.forwardRef((props, ref) => {
  const {
    disabledTooltipText,
    buttonType,
    buttonClassName,
    disabled,
    isFetching,
    disableModal,
    onClick,
    leftIcon,
    type,
    children,
    disableModalPopup,
    modalInfo = {},
    id,
    name,
    countdownTo,
    dataCy,
    formId = null
  } = props;

  const localization = useContext(AppComponentContext).strings;
  const [showModal, setShowModal] = useState(false);
  let enableModal = false;

  function buttonTypeSwitch() {
    switch (buttonType) {
      case 'primary':
        return styles.buttonPrimary;
      case 'primaryOutlined':
        return styles.buttonPrimaryOutlined;
      case 'secondary':
        return styles.buttonSecondary;
      case 'tertiary':
        return styles.buttonTertiary;
      case 'tertiaryOutlined':
        return styles.buttonTertiaryOutlined;
      case 'danger':
        enableModal = !disableModalPopup && true;
        return styles.buttonDanger;
      case 'dangerOutlined':
        return styles.buttonDangerOutlined;
      case 'success':
        return styles.buttonSuccess;
      case 'successOutlined':
        return styles.buttonSuccessOutlined;
      case 'text':
        return styles.buttonText;
      case 'gray':
        return styles.buttonGray;
      case 'grayOutlined':
        return styles.buttonGrayOutlined;
      case 'upload':
        return styles.buttonUpload;
      default:
        return styles.buttonSecondary;
    }
  }

  const classes = `${buttonTypeSwitch()} ${buttonClassName} ${disabled ? styles.disabled : ''}`;

  const clickHandler = useCallback(
    (e) => {
      if (!isFetching) {
        if (enableModal && !disableModal) {
          setShowModal(true);
        } else {
          onClick && onClick(e);
        }
      }
    },
    [disableModal, enableModal, isFetching, onClick]
  );

  const handleModalClick = () => {
    setShowModal(false);
    onClick();
  };

  const buttonComponent = useMemo(
    () => (
      <>
        {countdownTo ? (
          <ButtonCountdown {...props} classes={classes} ref={ref} clickHandler={clickHandler} />
        ) : (
          <button
            id={id}
            disabled={isFetching || disabled}
            ref={ref}
            className={classes}
            onClick={clickHandler}
            style={leftIcon && { paddingLeft: '10rem' }}
            type={type}
            name={name}
            data-cy={dataCy}
            {...(formId && { form: formId })}
          >
            {leftIcon && <div className={styles.leftIconWrapper}>{leftIcon}</div>}
            {isFetching ? <FourDotsLoader /> : null}
            {!isFetching ? <>{children}</> : null}
          </button>
        )}
      </>
    ),
    [
      countdownTo,
      props,
      classes,
      ref,
      clickHandler,
      id,
      isFetching,
      disabled,
      leftIcon,
      type,
      name,
      dataCy,
      formId,
      children
    ]
  );

  const buttonTooltipId = generateRandomId();

  return (
    <>
      {disabled && disabledTooltipText ? (
        <>
          <div className={styles.buttonWrapper} id={buttonTooltipId}>
            {buttonComponent}
            <Tooltip id={buttonTooltipId}>
              <span>{disabledTooltipText}</span>
            </Tooltip>
          </div>
        </>
      ) : (
        buttonComponent
      )}

      <ModalComponent
        isOpen={showModal}
        title={modalInfo.title ? modalInfo.title : localization.warning}
        message={modalInfo.message ? modalInfo.message : localization.areYouSureYouWantToContinue}
        redButtonText={localization.cancel}
        blueButtonText={modalInfo.btnText ? modalInfo.btnText : localization.yes}
        redFunction={() => setShowModal(false)}
        blueFunction={!isFetching ? handleModalClick : undefined}
        style='danger'
      />
    </>
  );
});

const ButtonCountdown = ({
  disabled,
  isFetching,
  leftIcon,
  type,
  children,
  id,
  name,
  classes,
  countdownTo,
  ref,
  clickHandler,
  dataCy
}) => {
  const [countdownText, animCountdownStarted, leftCountdownPercentage, animCountdownEnded] = useCountdown({
    expiresAt: countdownTo
  });

  return (
    <>
      {animCountdownEnded ? (
        <button
          id={id}
          disabled={isFetching || disabled}
          ref={ref}
          className={`${classes} ${styles.buttonWithCountdown}`}
          onClick={clickHandler}
          style={leftIcon && { paddingLeft: '10rem' }}
          type={type}
          name={name}
          data-cy={dataCy}
        >
          <CircleLoader width={25} height={25} />
          <span className={styles.countdownTime}>{countdownText}</span>
        </button>
      ) : (
        <button
          id={id}
          disabled={isFetching || disabled}
          ref={ref}
          className={`${classes} ${styles.buttonWithCountdown}`}
          onClick={clickHandler}
          style={leftIcon && { paddingLeft: '10rem' }}
          type={type}
          name={name}
          data-cy={dataCy}
        >
          {animCountdownStarted ? (
            <div
              className={styles.countdownAnimation}
              style={{
                width: `${100 - leftCountdownPercentage}%`
              }}
            ></div>
          ) : null}

          <div className={styles.buttonWithCountdownContainer}>
            <ClockSvg className={styles.clockSvg} /> <span>{children}</span>
            <span className={styles.countdownTime}>{countdownText}</span>
          </div>
        </button>
      )}
    </>
  );
};

/* 
 <button
    id={id}
    disabled={isFetching || disabled}
    ref={ref}
    className={classes}
    onClick={clickHandler}
    style={leftIcon && { paddingLeft: '10rem' }}
    type={type}
    name={name}
  >
    {leftIcon && <div className={styles.leftIconWrapper}>{leftIcon}</div>}
    {isFetching ? <CircleLoader width={35} height={35} /> : null}
    {!isFetching ? <span>{children}</span> : null}
  </button>
*/

Button.propTypes = {
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  text: PropTypes.string,
  buttonClassName: PropTypes.string,
  buttonType: PropTypes.string,
  isFetching: PropTypes.bool
};

Button.defaultProps = {
  buttonType: 'default',
  isFetching: false,
  disabled: false,
  buttonClassName: ''
};

Button.displayName = 'Button';

export default Button;
