import * as React from 'react';

function CloseSvg(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 18 18' {...props}>
      <defs>
        <filter id='pathShadow' x='0' y='0' width='110%' height='110%'>
          <feDropShadow dx='0' dy='0' stdDeviation='1' floodColor='rgba(0, 0, 0, 0.5)' />
        </filter>
      </defs>
      <path
        filter='url(#pathShadow)'
        d='M.548 17.337a1.722 1.722 0 010-2.518l5.873-5.872L.548 3.075a1.721 1.721 0 010-2.518 1.7 1.7 0 012.518 0L8.939 6.43 14.812.557a1.788 1.788 0 012.518 0 1.79 1.79 0 010 2.518l-5.873 5.872 5.873 5.872a1.769 1.769 0 010 2.518 1.788 1.788 0 01-2.518 0l-5.873-5.872-5.873 5.872a1.78 1.78 0 01-1.261.527 1.768 1.768 0 01-1.257-.527z'
      />
    </svg>
  );
}

export default CloseSvg;
