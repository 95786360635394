import { CHAT_MESSAGE_UPDATE } from '../../../constants/actions';

function setChatMessageUpdate(message) {
  return {
    type: CHAT_MESSAGE_UPDATE,
    message: message
  };
}

export function chatMessageUpdate(message) {
  return (dispatch) => {
    return dispatch(setChatMessageUpdate(message));
  };
}
